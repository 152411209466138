import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("chartkick/chart.js")
